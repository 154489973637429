import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const Breadcrumbs = ({ children }) => {
  return (
    <nav className="breadcrumbs" aria-label="breadcrumbs">
      <Breadcrumbs.Link to="/" className="breadcrumbs-link">
        Home
      </Breadcrumbs.Link>

      {children}
    </nav>
  )
}

Breadcrumbs.propTypes = {
  children: PropTypes.node,
}

Breadcrumbs.Link = ({ to, children }) => {
  return (
    <Link to={to} className="breadcrumbs-link">
      {children}
    </Link>
  )
}

export default Breadcrumbs
