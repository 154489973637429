import React from 'react'
import PropTypes from 'prop-types'

const Prose = ({ children, hasRawHtml }) => {
  // Raw HTML from Markdown.
  if (hasRawHtml) {
    // For raw HTML, we need to tag external links. That won't be taken care of
    // in Markdown content, etc.
    const html = children.replace(
      /href="(\/\/.+|https?:\/\/(?!(www\.smorescout\.com)))/gi,
      `data-event-category="External Links" target="_blank" rel="noreferrer" $&`
    )

    return (
      <div
        className="prose"
        dangerouslySetInnerHTML={{ __html: html }}
      />
    )
  // Normal node children
  } else {
    return (
      <div className="prose">
        {children}
      </div>
    )
  }
}

Prose.propTypes = {
  children: PropTypes.node.isRequired,
  hasRawHtml: PropTypes.bool,
}

export default Prose
