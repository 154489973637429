import React from 'react'
import { graphql } from 'gatsby'
import { Container, Row, Col } from 'react-bootstrap'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Section from '../components/section'
import Breadcrumbs from '../components/breadcrumbs'
import Prose from '../components/prose'

const PlaybookPost = ({ data }) => {
  const meta = data.markdownRemark.frontmatter

  return (
    <Layout currentSection="playbook">
      <SEO
        title={meta.title}
        description={meta.summary}
        meta={[
          {
            name: 'twitter:creator',
            content: '@chrisdpeters',
          },
        ]}
      >
        <link
          rel="canonical"
          href={`${data.site.siteMetadata.siteUrl}/playbook${data.markdownRemark.fields.slug}`}
        />
      </SEO>

      <Section>
        <Container>
          <Row className="justify-content-around">
            <Col xs={12} lg={8}>
              <Breadcrumbs>
                <Breadcrumbs.Link to="/playbook/">
                  Playbook
                </Breadcrumbs.Link>
              </Breadcrumbs>

              <h1>
                {meta.title}
              </h1>

              <p className="lead">
                {meta.summary}
              </p>

              <Prose hasRawHtml={true}>
                {data.markdownRemark.html}
              </Prose>

              <p className="pt-4">
                <em>
                  Last updated on{' '}
                  <time dateTime={meta.updatedOnIso}>
                    {meta.updatedOn}
                  </time>
                </em>
              </p>
            </Col>
          </Row>
        </Container>
      </Section>
    </Layout>
  )
}

export const query = graphql`
  query PlaybookPost($slug: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    markdownRemark(
      fields: {
        content_type: { eq: "playbooks" }
        slug: { eq: $slug }
      }
    ) {
      fields {
        slug
      }
      frontmatter {
        title
        summary
        updatedOnIso: updated_on(formatString: "YYYY-MM-DD")
        updatedOn: updated_on(formatString: "MMMM D, YYYY")
      }
      html
    }
  }
`

export default PlaybookPost
